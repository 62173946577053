/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  useFieldArray,
  type Control,
  type FieldErrors,
  type UseFormRegister,
  type UseFormGetValues,
  type UseFormTrigger,
} from 'react-hook-form';
import { Box, Button, Grid, Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useCallback, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { PatientSchema, SampleSchema } from '@/zod_schemas';
import PatientSampleForm from '../../Project/Forms/PatientSampleForm';
import BtnMenuSample from './BtnMenuSample';
import { getSampleColumns } from './sample/getSampleColumns';
import { defaultSample } from '../../Project/Forms/defaults';
import { useCreateSample } from '@/api/sample/sample';
import { usePatchPatientSamples } from '@/api/patient/patient';

interface PatientSampleListsProps {
  control: Control<PatientSchema>;
  register: UseFormRegister<PatientSchema>;
  errors: FieldErrors<PatientSchema>;
  setSampleGridData: React.Dispatch<React.SetStateAction<SampleSchema[]>>;
  trigger: UseFormTrigger<PatientSchema>;
  getValues: UseFormGetValues<PatientSchema>;
  sampleGridData: SampleSchema[];
  patientId?: string;
}
const PatientSampleLists = ({
  control,
  register,
  trigger,
  errors,
  setSampleGridData,
  sampleGridData,
  getValues,
  patientId,
}: PatientSampleListsProps) => {
  const [isAddSampleVisible, setIsAddSampleVisible] = useState(false);

  const { mutateAsync } = usePatchPatientSamples();

  const onRemoveSamplePatients = async (id: string[]) => {
    try {
      mutateAsync({ id: patientId!, sample_ids: id })
        .then(() => {
          enqueueSnackbar('Remove sample successfully', {
            variant: 'success',
          });
          const updatedData = sampleGridData.filter(
            (item) => !id.includes(item.id!),
          );
          setSampleGridData(updatedData);
        })
        .catch((_ex) => {
          enqueueSnackbar('Failed to remove sample', {
            variant: 'error',
          });
        });
    } catch (error) {
      enqueueSnackbar('Error to remove sample', {
        variant: 'error',
      });
    }
  };

  const columns = getSampleColumns({
    deleteAction: true,
    setSampleGridData,
    onRemoveSamplePatients,
    patientId,
  });
  const createSample = useCreateSample();
  const { append, fields, remove } = useFieldArray({
    control,
    name: 'samples',
    shouldUnregister: true,
  });

  const handleAddSampleClick = useCallback(
    (sampleCount: number) => {
      const appendPanel: SampleSchema = defaultSample;

      Array.from({ length: sampleCount }).forEach(() => append(appendPanel));

      setIsAddSampleVisible(true);
    },
    [append],
  );
  const onCheckValidation = useCallback(async () => {
    const isValid = await trigger('samples');

    if (isValid) {
      const handleCreateSample = async () => {
        try {
          let newSample = getValues('samples') || [];

          if (patientId) {
            // Add the patient_id field to each object(assign sample to patient)
            newSample = newSample.map((sample) => ({
              ...sample,
              patient_id: patientId,
            }));
          }
          createSample
            .mutateAsync(newSample)
            .then((response) => {
              const sampleResponse = response.data;
              enqueueSnackbar('Samples created successfully', {
                variant: 'success',
              });
              setSampleGridData((prevRows) => [...prevRows, ...sampleResponse]);
            })
            .catch((_ex) => {
              enqueueSnackbar('Failed to create sample[1]', {
                variant: 'error',
              });
            });
        } catch (ex) {
          enqueueSnackbar('Failed to create samples', { variant: 'error' });
        }
      };

      handleCreateSample(); // Only call this function when sampleGridData changes

      setIsAddSampleVisible(false); // Hide the form after adding samples
      remove(); // Reset the form or perform any clean-up
    } else {
      console.error(errors.samples?.message); // Log validation errors
    }
  }, [
    getValues,
    setSampleGridData,
    trigger,
    errors,
    remove,
    createSample,
    patientId,
  ]);

  const handleCancelClick = () => {
    setIsAddSampleVisible(false);
    remove();
  };

  return (
    <Box>
      <Grid
        item
        xs={12}
        sm={6}
        justifyContent={{
          xs: 'center',
          sm: 'flex-end',
        }}
        sx={{ display: 'flex', padding: '50px 0 10px 0' }}
      >
        <BtnMenuSample
          setSampleGridData={setSampleGridData}
          handleAddSampleClick={handleAddSampleClick}
        />
      </Grid>
      <DataGrid
        rows={sampleGridData}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        sx={{ height: 400 }}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
      />
      {isAddSampleVisible && (
        <>
          <Grid
            item
            xs={12}
            sm={6}
            justifyContent={{
              xs: 'center',
              sm: 'flex-end',
            }}
            sx={{ display: 'flex', padding: '50px 0 10px 0' }}
          >
            <Stack spacing={1} direction="row">
              <Button
                type="button"
                variant="contained"
                color="error"
                size="small"
                onClick={handleCancelClick}
              >
                Cancel
              </Button>
              <Button
                type="button"
                variant="contained"
                color="primary"
                size="small"
                onClick={onCheckValidation}
              >
                Confirm
              </Button>
            </Stack>
          </Grid>
          {fields.map(({ id }, index) => (
            <PatientSampleForm
              register={register}
              remove={() => remove(index)}
              index={index}
              experimentIndex={0}
              key={id}
              errors={errors}
              control={control}
            />
          ))}
        </>
      )}
    </Box>
  );
};

export default PatientSampleLists;
