import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import {
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
} from 'react-hook-form';
import { PermissionFormElement } from '../Styles/StyledComponents';
import { PermissionContentTypeSchema, PermissionSchema } from '@/zod_schemas';

interface CreateEditPermissionProps {
  permissionGroups: PermissionContentTypeSchema[];
  register: UseFormRegister<PermissionSchema>;
  errors: FieldErrors<PermissionSchema>;
  handleSubmit: UseFormHandleSubmit<PermissionSchema>;
  onSubmit: SubmitHandler<PermissionSchema>;
  handleSelectAll: (id: number) => void;
  handleTogglePermission: (groupIndex: number, permissionIndex: number) => void;
}

const CreateEditPermission = ({
  permissionGroups,
  register,
  errors,
  handleSubmit,
  onSubmit,
  handleSelectAll,
  handleTogglePermission,
}: CreateEditPermissionProps) => (
  <Paper
    elevation={4}
    sx={{ padding: 4, borderRadius: 3, backgroundColor: grey[50] }}
  >
    <Stack spacing={4}>
      <PermissionFormElement onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            position: 'sticky',
            top: 80, // Adjust based on header height
            backgroundColor: '#FAFAFA', // Matches MUI's form background color
            padding: '16px',
            zIndex: 100,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid',
          }}
        >
          <Typography variant="h6" color="primary" sx={{ fontWeight: 'bold' }}>
            Edit Permissions Group
          </Typography>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Name"
              variant="outlined"
              {...register('name')}
              error={!!errors?.name}
              helperText={errors?.name?.message}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Description"
              variant="outlined"
              {...register('description')}
              error={!!errors?.description}
              helperText={errors?.description?.message}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {permissionGroups.map((group, groupIndex) => (
            <Grid item xs={12} md={6} key={group.content_type.id}>
              <Box
                sx={{
                  padding: 2,
                  border: `1px solid ${grey[300]}`,
                  borderRadius: 2,
                  backgroundColor: grey[100],
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ mb: 1 }}
                >
                  <Typography
                    variant="subtitle1"
                    color="secondary"
                    sx={{ fontWeight: 600 }}
                  >
                    {group.content_type.model} Permissions
                  </Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={group.permissions.every(
                          (perm) => perm.allowed,
                        )}
                        onChange={() => handleSelectAll(groupIndex)}
                        color="primary"
                      />
                    }
                    label="Select All"
                    labelPlacement="start"
                  />
                </Stack>
                <Divider sx={{ mb: 1 }} />
                {group.permissions.map((permission, permissionIndex) => (
                  <Grid
                    container
                    key={permission.id}
                    alignItems="center"
                    sx={{ py: 0.5 }}
                  >
                    <Grid item xs={6}>
                      <Typography>{permission.name}</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign="right">
                      <Switch
                        size="small"
                        checked={permission.allowed}
                        onChange={() =>
                          handleTogglePermission(groupIndex, permissionIndex)
                        }
                        color="primary"
                      />
                    </Grid>
                  </Grid>
                ))}
              </Box>
            </Grid>
          ))}
        </Grid>
      </PermissionFormElement>
    </Stack>
  </Paper>
);

export default CreateEditPermission;
