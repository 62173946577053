import { useState } from 'react';
import {
  Grid,
  IconButton,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { SampleResultSchema } from '@/zod_schemas';
import { useExperimentStatus } from '@/api/experiment/experiment';

interface ProgressDetailsProps {
  result: SampleResultSchema;
}
const getSteps = (status: string) => {
  // Determine steps based on the status
  const baseSteps = ['Pending', 'Waiting', 'Running'];
  return status === 'failed'
    ? [...baseSteps, 'Failed']
    : [...baseSteps, 'Completed'];
};

const expProgress = (status: string) => {
  switch (status) {
    case 'failed':
      return 4; // Corresponds to "Failed" as the last step
    case 'completed':
      return 4; // Corresponds to "Completed" as the last step
    case 'in_progress':
      return 2; // Running
    case 'waiting':
      return 1; // Waiting
    default:
      return 0; // Pending
  }
};

const ProgressDetails = ({ result }: ProgressDetailsProps) => {
  const expId = result.id;
  const { data, refetch } = useExperimentStatus({ id: expId });

  const [currentStatus, setCurrentStatus] = useState(data?.status || 'pending');

  const steps = getSteps(currentStatus); // Dynamically get steps based on the status
  const activeStep = expProgress(currentStatus);

  const onRefresh = async () => {
    const { data: updatedData } = await refetch();
    setCurrentStatus(updatedData!.status); // Update status based on refetched data
  };

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        padding: '1rem',
        backgroundColor: '#f0f0f0',
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.12)',
      }}
    >
      <Grid container alignItems="center" justifyContent="space-between">
        <Typography variant="h6" fontWeight="bold">
          Experiment Status
        </Typography>
        <IconButton
          onClick={onRefresh}
          sx={{
            color: '#606060',
            '&:hover': {
              color: '#1976d2', // Blue color on hover
            },
          }}
        >
          <RefreshIcon />
        </IconButton>
      </Grid>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => {
          const labelProps = {
            error: false,
          };
          if (label === 'Failed') {
            labelProps.error = true;
          }

          return (
            <Step key={label}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Stack>
  );
};

export default ProgressDetails;
