import { Suspense, useEffect, useState } from 'react';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useCreateOrUpdatePermission, usePermissionFormHandler } from './hooks';
import { PermissionFormElement } from './Styles/StyledComponents';
import { useGetPermissionContent } from '@/api/permission/permission';
import { PermissionContentTypeSchema } from '@/zod_schemas';
import AssignGroupPermission from './AssignGroupPermission';

const AssignPermission = () => {
  const { handleSubmit, getValues, mode, permissionId } =
    usePermissionFormHandler();

  const { data, isLoading, error } = useGetPermissionContent();

  const [permissionGroups, setPermissionGroups] = useState<
    PermissionContentTypeSchema[]
  >([]);

  useEffect(() => {
    if (data && getValues) {
      const initialPermissions = data.map((group) => ({
        ...group,
        permissions: group.permissions.map((permission) => ({
          ...permission,
          allowed:
            mode !== 'create' &&
            getValues().permissions?.includes(permission.id),
        })),
      }));
      setPermissionGroups(initialPermissions);
    }
  }, [data, getValues, mode]);

  const onSubmit = useCreateOrUpdatePermission(
    mode,
    permissionGroups,
    permissionId,
  );

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography color="error">Failed to load permissions.</Typography>;
  }

  return (
    <Paper
      elevation={4}
      sx={{ padding: 4, borderRadius: 3, backgroundColor: grey[50] }}
    >
      <Stack spacing={4}>
        <PermissionFormElement onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              position: 'sticky',
              top: 80, // Adjust based on header height
              backgroundColor: '#FAFAFA', // Matches MUI's form background color
              padding: '16px',
              zIndex: 100,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px solid',
            }}
          >
            <Typography
              variant="h6"
              color="primary"
              sx={{ fontWeight: 'bold' }}
            >
              Assign/Remove User
            </Typography>
          </Box>
          <AssignGroupPermission permissionId={permissionId!} />
        </PermissionFormElement>
      </Stack>
    </Paper>
  );
};

const AssignPermissionWithSuspense = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <AssignPermission />
  </Suspense>
);

export default AssignPermissionWithSuspense;
