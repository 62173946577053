import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { post, get, put, patch, del } from '@api/httpClient';
import { AxiosResponse } from 'axios';
import { APIError, ResourceWithId } from '../types';
import queryClient from '../queryClient';
import { PatientSchema } from '@/zod_schemas';

const QUERY_KEYS = {
  PATIENTS: ['patient'],
  PATIENT: (patientId: string) => ['patient', { id: patientId }],
};

export const useGetPatient = (patientId?: string) =>
  useSuspenseQuery<PatientSchema>({
    queryKey: QUERY_KEYS.PATIENT(patientId || ''),
    queryFn: async () => {
      if (!patientId) {
        const resp = await get('/patient/');
        return resp.data;
      }

      const resp = await get(`/patient/${patientId}/`);
      return resp.data;
    },
  });

export const useCreatePatient = () =>
  useMutation<AxiosResponse<PatientSchema>, APIError, PatientSchema>({
    mutationFn: (patient) =>
      post<PatientSchema>('/patient_hpo_sample/', { ...patient }),
  });

export const usePatchPatient = () =>
  useMutation<
    AxiosResponse<PatientSchema>,
    APIError,
    ResourceWithId<PatientSchema>
  >({
    mutationFn: ({ id, payload }) =>
      patch<PatientSchema>(`/patient/${id}/`, { ...payload }),
    onSuccess: ({ data: { id } }) => {
      if (!id) return;

      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.PATIENTS,
      });
    },
  });
export const usePutPatient = () =>
  useMutation<
    AxiosResponse<PatientSchema>,
    APIError,
    ResourceWithId<PatientSchema>
  >({
    mutationFn: ({ id, payload }) =>
      put<PatientSchema>(`/patient/${id}/`, { ...payload }),
    onSuccess: ({ data: { id } }) => {
      if (!id) return;

      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.PATIENTS,
      });
    },
  });

export const useDeletePatient = () =>
  useMutation<AxiosResponse<string>, APIError, string>({
    mutationFn: (id: string) => del<string>(`/patient/${id}/`),
  });

interface RemoveSamplesPayload {
  id: string; // Patient ID
  sample_ids: string[]; // List of sample IDs
}

export const usePatchPatientSamples = () =>
  useMutation<AxiosResponse<string[]>, APIError, RemoveSamplesPayload>({
    mutationFn: async ({ id, sample_ids }) =>
      post<string[]>(`/patient/${id}/remove_samples/`, {
        sample_ids,
      }),
  });

export const useAssignPatientSamples = () =>
  useMutation<AxiosResponse<string[]>, APIError, RemoveSamplesPayload>({
    mutationFn: async ({ id, sample_ids }) =>
      post<string[]>(`/patient/${id}/assign_samples/`, {
        sample_ids,
      }),
  });
