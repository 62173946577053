import { Suspense, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import EditIcon from '@mui/icons-material/Edit';
import { useCreateOrUpdatePermission, usePermissionFormHandler } from './hooks';
import { useGetPermissionContent } from '@/api/permission/permission';
import { PermissionContentTypeSchema } from '@/zod_schemas';
import AssignPermissionWithSuspense from './AssignPermission';
import CreateEditPermission from './Form/CreateEditPermission';

const PermissionsForm = () => {
  const {
    handleSubmit,
    register,
    getValues,
    mode,
    permissionId,
    formState: { errors },
  } = usePermissionFormHandler();

  const pageStatus = mode === 'create' ? 'Create' : 'Edit';

  const theme = useTheme();

  const { data } = useGetPermissionContent();

  const [permissionGroups, setPermissionGroups] = useState<
    PermissionContentTypeSchema[]
  >([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    if (data) {
      // Initialize `allowed` state for each permission based on getValues().permissions
      const initialPermissions = data.map((group) => ({
        ...group,
        permissions: group.permissions.map((permission) => ({
          ...permission,
          allowed:
            mode !== 'create'
              ? getValues().permissions!.includes(permission.id)
              : false, // Set allowed based on formData
        })),
      }));
      setPermissionGroups(initialPermissions);
    }
  }, [data, getValues, mode]);

  const handleTogglePermission = (
    groupIndex: number,
    permissionIndex: number,
  ) => {
    const updatedGroups = [...permissionGroups];
    const currentPermission =
      updatedGroups[groupIndex].permissions[permissionIndex];
    currentPermission.allowed = !currentPermission.allowed;
    setPermissionGroups(updatedGroups);
  };

  const handleSelectAll = (groupIndex: number) => {
    const updatedGroups = [...permissionGroups];
    const allAllowed = updatedGroups[groupIndex].permissions.every(
      (perm) => perm.allowed,
    );
    updatedGroups[groupIndex].permissions = updatedGroups[
      groupIndex
    ].permissions.map((perm) => ({
      ...perm,
      allowed: !allAllowed,
    }));
    setPermissionGroups(updatedGroups);
  };

  const onSubmit = useCreateOrUpdatePermission(
    mode,
    permissionGroups,
    permissionId,
  );

  const handleListItemClick = (
    _event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
  };

  return (
    <Box padding={3}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <List component="nav" aria-label="main permission">
            <ListItemButton
              selected={selectedIndex === 0}
              onClick={(event) => handleListItemClick(event, 0)}
              sx={{
                borderRadius: '8px',
                backgroundColor:
                  selectedIndex === 0 ? theme.palette.primary.light : 'white',
                position: 'relative',
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                },
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  bottom: 0,
                  width: '4px',
                  backgroundColor:
                    selectedIndex === 0
                      ? theme.palette.primary.main
                      : 'transparent',
                  borderRadius: '8px 0 0 8px',
                },
              }}
            >
              <ListItemIcon>
                <EditIcon color={selectedIndex === 0 ? 'primary' : 'inherit'} />
              </ListItemIcon>
              <ListItemText
                primary="Permission"
                secondary={`${pageStatus} permission group`}
                primaryTypographyProps={{
                  fontWeight: selectedIndex === 0 ? 'bold' : 'normal',
                }}
              />
            </ListItemButton>
            {pageStatus === 'Edit' && (
              <ListItemButton
                selected={selectedIndex === 1}
                onClick={(event) => handleListItemClick(event, 1)}
                sx={{
                  borderRadius: '8px',
                  backgroundColor:
                    selectedIndex === 1 ? theme.palette.primary.light : 'white',
                  position: 'relative',
                  '&:hover': {
                    backgroundColor: theme.palette.action.hover,
                  },
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    width: '4px',
                    backgroundColor:
                      selectedIndex === 1
                        ? theme.palette.primary.main
                        : 'transparent',
                    borderRadius: '8px 0 0 8px',
                  },
                }}
              >
                <ListItemIcon>
                  <GroupIcon
                    color={selectedIndex === 1 ? 'primary' : 'inherit'}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Users"
                  secondary="Assign/Remove user"
                  primaryTypographyProps={{
                    fontWeight: selectedIndex === 1 ? 'bold' : 'normal',
                  }}
                />
              </ListItemButton>
            )}
          </List>
        </Grid>
        {/* Additional Cards for permission/users, etc. */}
        <Grid item xs={12} md={9}>
          {selectedIndex === 0 ? (
            <CreateEditPermission
              register={register}
              permissionGroups={permissionGroups}
              errors={errors}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              handleSelectAll={handleSelectAll}
              handleTogglePermission={handleTogglePermission}
            />
          ) : (
            <AssignPermissionWithSuspense />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

const PermissionWithSuspense = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <PermissionsForm />
  </Suspense>
);

export default PermissionWithSuspense;
