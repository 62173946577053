// src/config/gridColumns.ts
import { GridColDef } from '@mui/x-data-grid';
import { Box, Button } from '@mui/material';
import { SampleSchema } from '@/zod_schemas';

interface GetSampleColumnsProps {
  deleteAction?: boolean;
  selectAction?: boolean;
  setSampleGridData: React.Dispatch<React.SetStateAction<SampleSchema[]>>;
  setDialogOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  formMode?: string;
  formPatientId?: string;
  onRemoveSamplePatients?: (id: string[]) => void;
  patientId?: string;
  onAssignSampleToPatient?: (id: string[]) => void;
}

export const getSampleColumns = ({
  deleteAction = false, // Ensure deleteAction defaults to false
  selectAction = false, // Ensure selectAction defaults to false
  setSampleGridData,
  setDialogOpen,
  onRemoveSamplePatients,
  patientId,
  onAssignSampleToPatient,
}: GetSampleColumnsProps): GridColDef<SampleSchema>[] => {
  const columns: GridColDef<SampleSchema>[] = [
    {
      field: 'sample_id',
      headerName: 'Sample ID',
      flex: 1,
    },
    {
      field: 'files',
      headerName: 'Files',
      flex: 1,
      renderCell: (params) =>
        params.row.files.map((file) => file.file_path).join(', '),
      // renderCell: (params) => (
      //   <Box sx={{ whiteSpace: 'pre-line' }}>{params.value.join('\n')}</Box>
      // ),
    },
  ];

  if (deleteAction) {
    columns.push({
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      flex: 0.8,
      renderCell: (params) => (
        <Box>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => {
              const rowParams = params.row.id;
              if (patientId && onRemoveSamplePatients) {
                onRemoveSamplePatients([rowParams!]);
              }
            }}
            style={{ marginLeft: 8 }}
          >
            Delete
          </Button>
        </Box>
      ),
    });
  }

  if (selectAction) {
    columns.push({
      field: 'actions2',
      headerName: 'Actions2',
      sortable: false,
      flex: 0.8,
      renderCell: (params) => (
        <Box>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => {
              if (patientId && onAssignSampleToPatient) {
                onAssignSampleToPatient([params.row.id!]);
              }

              // Your select action here
              const rowParams = params.row;
              setSampleGridData((prevRows) => {
                // Check for duplication hpo
                if (Array.isArray(rowParams)) {
                  const existingIds = new Set(prevRows.map((row) => row.id));
                  const filteredRows = rowParams.filter(
                    (row) => !existingIds.has(row.id),
                  );
                  return [...prevRows, ...filteredRows];
                }

                if (rowParams) {
                  const isDuplicate = prevRows.some(
                    (row) => row.id === rowParams.id,
                  );
                  if (!isDuplicate) {
                    return [...prevRows, rowParams];
                  }
                }

                return prevRows;
              });

              if (setDialogOpen) {
                setDialogOpen(false);
              }
            }}
            style={{ marginLeft: 8 }}
          >
            Select
          </Button>
        </Box>
      ),
    });
  }

  return columns;
};
